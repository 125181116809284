/**
 * Theme for Virgin AU site
 **/
import { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types.d';
import { buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW, buildThemeVarsAPACLW } from 'dw-apac-law/theme';
import { montserratFont } from '../common/nextFont';

export const buildThemeVarsAPACVir = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const virRed500 = '#E11931'; //color4
  const virRed600 = '#BB0C29'; //color5
  const virRed700 = '#FBE6F1'; //color6
  const virPurple100 = '#F0EAF3'; //color7
  const virPurple300 = '#C2A7CF'; //color8
  const virPurple500 = '#6A288A'; //color9
  const virPurple700 = '#4A1C61'; //color10
  const virPurple800 = '#370A4C'; //color11
  //secondary
  const virCream300 = '#F8F5EF'; //color12
  const virCream500 = '#EFECE8'; //color13
  const virWhite = '#FFFFFF'; //color14
  const virGrey100 = '#F8F8F8'; //color15
  const virGrey200 = '#EDEDED'; //color16
  const virGrey400 = '#BCBCBC'; //color17
  const virGrey600 = '#767676'; //color18
  const virGrey700 = '#616161'; //color19
  const virGrey800 = '#494949'; //color20
  const virBlack = '#222222'; //color21
  //accent-purple
  const virLavender = '#DCD4EA'; //color22
  const virLilac = '#977DC1'; //color23
  //accent-Green
  const virGreen = '#35B63E'; //color24
  const virTeal = '#469D8E'; //color25
  //accent-Gold
  const virGold = '#DFB158'; //color26
  const lwPink = '#CF004F';
  const virRed = '#D62021';

  const virOceanGreen = '#009F8E';

  const montserrat = montserratFont.style.fontFamily;

  const lwThemeVars = buildThemeVarsAPACLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Virgin AU',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-white.svg`,
    },
    colors: {
      primary1: virRed500,
      primary2: virPurple500,
      primary3: virCream300,
      primary4: virRed500,
      primary5: virRed700,
      primary6: lwPink,
      primary7: virPurple100,
      primary8: virBlack,
      primary9: virPurple500,
      primary10: virPurple700,
      primary11: virPurple800,
      secondary1: virCream300,
      secondary2: virCream500,
      secondary3: virWhite,
      secondary4: virGrey100,
      secondary5: virGrey200,
      secondary6: virGrey400,
      secondary7: virGrey600,
      secondary8: virGrey700,
      secondary9: virGrey800,
      secondary10: virBlack,
      secondary11: virOceanGreen,
      accentLavender: virLavender,
      accentLilac: virLilac,
      accentGreen: virGreen,
      accentTeal: virTeal,
      accentGold: virGold,
      accentThunderbird500: virRed,
      textPrimaryBg: virRed500,

      //specific colour definitions
      btnsPrimaryBg: virRed500,
      btnsPrimaryColor: vars.colors.white,
      btnsPrimaryHoverBg: virRed600,
      btnsPrimaryHoverColor: vars.colors.white,
      btnsSecondaryBg: vars.colors.white,
      btnsSecondaryColor: vars.colors.black,
      btnsSecondaryHoverBg: virRed600,
      btnsSecondaryHoverColor: vars.colors.white,
      btnsTertiaryBg: vars.colors.white,
      btnsTertiaryColor: vars.colors.black,
      btnsTertiaryBorder: vars.colors.white,
      btnsTertiaryHoverBg: vars.colors.white,
      btnsTertiaryHoverColor: vars.colors.black,

      footerPrimaryBg: virRed500,
      footerdrinkaware: vars.colors.white,

      bgColors: {
        virRed: virRed500,
        virPurple: virPurple500,
        virCream: virCream500,
        virWhite: virWhite,
        virBlack: virBlack,
      },
    },
    fonts: {
      familyPrimary: montserrat,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTertiary: montserrat,
      headerMainNavTitle: montserrat,
      headerMainNavSubTitle: montserrat,
      weightLight: '300',
      weightNormal: '400',
      weightMedium: '500',
      weightMediumPlus: '600',
      weightBold: '700',
    },
  });
};

export const buildThemeMixinsAPACVir = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buildThemeWidget = buildThemeMixinsAPACLW(vars);

  return merge({}, buildThemeWidget, {
    h1: `
         font-family: ${vars.fonts.familyPrimary};
         font-size: 28px;
         font-weight: ${vars.fonts.weightNormal};
         ${vars.breakpoints.md} {
           font-size: ${vars.fonts.sizeH1};
         }
         `,
    h2: `
         font-family: ${vars.fonts.familyTertiary};
         font-size: 24px;
         font-weight: ${vars.fonts.weightNormal};
         ${vars.breakpoints.md} {
           font-size: ${vars.fonts.sizeH2};
         }
         `,
    h3: `
         font-family: ${vars.fonts.familyTertiary};
         font-size: ${vars.fonts.sizeH3};
         font-weight: ${vars.fonts.weightNormal};
         `,
  });
};

export const buildThemeWidgetStylesAPACVir = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesAPACLW(vars);

  return merge({}, buildThemeWidget, {
    GlobalHeader: {
      headerLogo: `
      img {
        width: 32px;
        height: 28px;
        ${vars.breakpoints.sm} {
          width: 40px;
          height: 35px;
        }
        ${vars.breakpoints.md} {
          width: 76px;
          height: 65px;
        }
      }`,
    },
    Search: {
      hitWrapper: ` 
      ${buildThemeWidget.Search.hitWrapper}
      &.grid-view {
        .ais-Hits-list {
          .ais-Hits-item {
          .hit-content {
            .title {
              font-family: ${vars.fonts.familyTertiary};
            }
          }
          .addToCart-container { 
            .price-section {
              .sku-price {
                .vpp-price-section {
                  font-family: ${vars.fonts.familySecondary};
                }
              }
              .member-price-section-case {
                font-size: ${vars.fonts.sizeDefault};
              }
            }
          }
        }
      }
      `,
    },
    Footer: {
      default: `
      ${buildThemeWidget.Footer.default}
      font-family: ${vars.fonts.familySecondary};
      p {
        font-family: ${vars.fonts.familySecondary};
      }
      @media print {
        display: none;
      }
      .footer-bottom-section {
        background: ${vars.colors.footerPrimaryBg};
        box-shadow: 0 0 0 100vmax ${vars.colors.footerPrimaryBg};
        clip-path: inset(0 -100vmax);
        color: ${vars.colors.white};
        .wrapper {
          .copyrights {
            p {
              color: ${vars.colors.white};
              a {
                color: ${vars.colors.white};
              }
            }
          }
          .brand img {
              max-width: 6.5em;
          }
          ${vars.breakpoints.lg} {
            .bedrinkaware-group {
              justify-content: flex-start;
            }
          }
        }
      }
      .footer-nav-links {
        background: ${vars.colors.secondary1};
        box-shadow: 0 0 0 100vmax ${vars.colors.secondary1};
      }
      .social-connections {
        .wrapper {
          .social-connection-heading p {
            font-family: ${vars.fonts.familySecondary};
            letter-spacing: 2px;
          }
        }
      }
      `,
      navItems: `
        ${buildThemeWidget.Footer.navItems}
        > div:first-of-type p{
          text-transform: uppercase;
          font-weight: bold;
        }
  `,
    },
    Cart: {
      MiniCart: `
      ${buildThemeWidget.Cart.MiniCart}
      .mini-cart-header {
        i {
          color:rgb(0, 110, 26);
        }
        .mini-cart-header-text{
          font-family: ${vars.fonts.familyPrimary};
          font-weight: ${vars.fonts.weightMediumPlus};
          letter-spacing: -0.03em;
          font-size: 28px;
          line-height: 29.4px;
          ${vars.breakpoints.md}{ 
            font-size: 36px;
            line-height: 37.8px;
          }
        }
      }
      .mantine-Modal-content .mantine-Modal-close {
          color: ${vars.colors.secondary10};
      }
      .product-great-pick{
        font-weight: ${vars.fonts.weightNormal};
        font-family: ${vars.fonts.familyPrimary};
        letter-spacing: -0.01em;
      }

      .product-link {
        color: ${vars.colors.secondary10};
        font-family: ${vars.fonts.familyPrimary};
        font-weight: ${vars.fonts.weightNormal};
        font-size: 20px;
        line-height: 23px;
        ${vars.breakpoints.md}{ 
          font-size: 24px;
          line-height: 27.6px;
        }
      }
      .product-detail-text .checkout-item-qty {
        font-family: ${vars.fonts.familyPrimary};
      }

      .price-section {
        ${vars.breakpoints.md}{ 
          flex-flow: column;
          align-items: baseline;
        }
        .price {
          font-family:${vars.fonts.familyPrimary};
          font-weight: ${vars.fonts.weightNormal};
        }
      }

      .price-section .strike,  .price-section .sale-price {
        ${vars.breakpoints.md}{ 
          font-size: 24px;
          line-height: 27.6px;
        }
      }

      .price-section .strike+.sale-price {
        color: ${vars.colors.accentThunderbird500}
      }

      .price-section .strike+.member-price {
        color:${vars.colors.secondary11};
      }

      .member-savings {
        color: ${vars.colors.secondary11};
        font-weight: ${vars.fonts.weightNormal};
      }

      .gift-other-device h4, .gift-tablet h4 {
        color: ${vars.colors.secondary10};
      } 
    `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAPACVir, buildThemeMixinsAPACVir, buildThemeWidgetStylesAPACVir);

export default createTheme;
